
.el-input.el-input__inner {
    width: 100% !important;
}
.el-date-editor .el-input__inner {
    height: calc(1.5em + 0.75rem + 2px) !important;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100% !important;
}
.el-select {
    width: 100% !important;
}

