.documenter {
    font-family: Monaco,Consolas,"Courier New",monospace;
    font-size: 0.8em;

    padding: 9.5px;
    margin: 0 0 10px;
    line-height: 1.3;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
}
