@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

//$input-border-color: $gray-500;
//$form-select-border-color: $gray-500;
//$input-group-addon-border-color: $gray-500;
//@import "~bootstrap/scss/bootstrap";


@import "data-view.scss";
@import "element-ui.scss";
@import "utils.scss";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~tom-select/dist/scss/tom-select.bootstrap5";
@import "~element-ui/lib/theme-chalk/index.css";

